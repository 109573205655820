import React from 'react';
import Bridge from './Bridge';
// import Transfer from './Transfer';
import { Grid } from '@material-ui/core';

const Home = ({ chainId, account }) => {
  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Bridge chainId={chainId} account={account} />
        </Grid>

        {/* <Grid item>
          <Transfer />
        </Grid> */}
      </Grid>
    </>
  );
};

export default Home;
