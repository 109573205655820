import React from "react";
const Logo = (props) => {
  return (
    <div
      style={{
        width: 80,
        height: 80,
        background: "#231F20",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <img
        src="/images/logo.png"
        alt="Logo"
        {...props}
        style={{ width: "70%" }}
      />
    </div>
  );
};

export default Logo;
