import React, { useState, useEffect } from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import useAccount from './hooks/useAccount';
import useChainId from './hooks/useChainId';
import { connectToWallet } from './hooks/operations';

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
});
function App() {
  const changeAccout = useAccount();
  const chainId = useChainId();
  const [accountAdd, setAccount] = useState(changeAccout);
  const connect = async () => {
    if (window.ethereum) {
      const res = await connectToWallet();
      setAccount(res);
    } else {
      alert('Please Install Metamask');
    }
  };

  useEffect(() => {
    setAccount(changeAccout);
  }, [changeAccout]);

  return (
    <ThemeProvider theme={theme}>
      <Header account={accountAdd} connect={connect} />
      <Home chainId={chainId} account={accountAdd} />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
