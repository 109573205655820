import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Container, Box, Divider, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import EthLogo from "../../images/ethman.png";
import BinanceLogo from "../../images/binance.png";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TextField from "@material-ui/core/TextField";
import Web3 from "web3";
import axios from "axios";
import ButtonCircularProgress from "../../components/ButtonCircularProgress";
import { ethers } from "ethers";
import {
  BSCswap,
  ETHswap,
  BSCSwapAgentABIData,
  EthSwapAgentABIData,
  IBEP20ABIData,
  BinanceTokenAddress,
  TokenValue,
  EthereumChainId,
  BinanceChainId,
  ETHTokenAddress,
  BEP20IMPLABIData,
  binanceNode,
  ethNode,
} from "../../Utils/index";
import {
  getBinanceBalance,
  getEthereumBalance,
  sortAddress,
  getTotalSupply,
} from "../../hooks/operations";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import { fillETH2BSCSwapFun } from '../../Utils/fillETH2BSCSwap';

// import { fillBSC2ETHSwapFun } from '../../Utils/fillBSC2ETHSwap';

const useStyles = makeStyles((theme) => ({
  containerLeft: {
    borderRight: "1px solid #90c7c6",
    position: "relative",
  },

  blueText: {
    color: "#015284",
  },
  arrowIcon: {
    position: "absolute",
    top: "53px",
    right: "-38px",
    fontSize: "46px",
    backgroundColor: "#c5f1fb",
    borderRadius: "50%",
    padding: "15px",
  },
  caption: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: " 17px",
    fontWeight: "400",
    lineHeight: "1.66",
  },

  itemBox: {
    padding: "20px 25px",
  },

  dialogueContent: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },

  links: {
    width: "20px",
    paddingLeft: "12px",
  },

  curvedDialogue: {
    borderRadius: "22px",
  },

  mainContainer: {
    backgroundColor: "#ffffff",
    padding: "2%",
    borderRadius: "12px",
  },

  transferButton: {
    height: "60px",
    fontSize: "18px",
    minWidth: "235px",
    borderRadius: "8px",
    backgroundColor: "#27B5E7",
    color: "#CAE9FF",
    margin: 3,

    "&:hover": {
      background: "#0166a5",
      color: "#CAE9FF",
    },
  },

  textField: {
    fontSize: "33px",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Bridge = ({ chainId, account }) => {
  const classes = useStyles();
  const [openEthereum, setEthereum] = useState(false);
  const [openBinance, setBinance] = useState(false);
  const [tokenBalanceBinance, setTokenBalanceBinance] = useState("");
  const [totlaSupplyBinance, setTotlaSupplyBinance] = useState(0);
  const [tokenBalanceEthereum, setTokenBalanceEthereum] = useState("");
  const [totalSupplyEthereum, setTotalSupplyEthereum] = useState(0);
  const [transferAmount, setTransferAmount] = useState("");
  const [isUpdatingTransfer, setIsUpdatingTransfer] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [misMatchArraw, setMisMatchArraw] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorMSG, setErrorMSG] = useState("");
  const [isError, setIsError] = useState(false);
  const [currentChain, setCurrentChain] = useState("");

  const toogleArrawHandler = () => {
    const NewcurrentChain =
      currentChain === BinanceChainId ? EthereumChainId : BinanceChainId;
    if (currentChain === BinanceChainId) {
      setCurrentChain(NewcurrentChain);
    } else {
      setCurrentChain(NewcurrentChain);
    }

    if (parseInt(chainId) === NewcurrentChain) {
      setMisMatchArraw(false);
    } else {
      setMisMatchArraw(true);
    }
  };

  useEffect(() => {
    if (chainId !== null) {
      if (
        parseInt(chainId) === EthereumChainId ||
        parseInt(chainId) === BinanceChainId
      ) {
        setCurrentChain(parseInt(chainId));
        if (parseInt(chainId) === currentChain) {
          setMisMatchArraw(false);
        }
      } else {
        setMisMatchArraw(true);
      }
    }
  }, [chainId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMisMatchArraw(false);
    setIsError(false);
    setErrorMSG("");
  };

  const transferHandlerBNB = async () => {
    if (transferAmount !== "" && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(BSCSwapAgentABIData, BSCswap);
        contract.methods
          .swapBSC2ETH(
            ETHTokenAddress,
            web3.utils.toWei(transferAmount.toString())
          )
          .send({ from: account, gas: 3000000, value: TokenValue })
          .then(async function (receipt) {
            if (receipt.status) {
              setOpen(true);
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                "fillBSC2ETHSwapFun"
              );
              setIsApprove(false);
              setOpen(false);
              setTransferAmount("");
              getBalanceFun(account);
              setIsUpdatingTransfer(false);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .catch((err) => {
            setErrorMSG("Failed");
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.log("ERROR", err);
          });
      } catch (err) {
        console.log("err", err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const approveTokenBNB = async () => {
    setIsError(false);
    if (transferAmount !== "" && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));

        const contract = new web3.eth.Contract(
          BEP20IMPLABIData,
          "0x5a3102D2Aaac7a8B96C101FB7357D00a582C5954"
        );

        const transactionParameters = {
          to: ETHTokenAddress,
          from: account,
          data: contract.methods
            .approve(BSCswap, web3.utils.toWei(transferAmount.toString()))
            .encodeABI(),
        };
        web3.eth
          .sendTransaction(transactionParameters)
          .on("transactionHash", (hash) => {})
          .once("confirmation", (confirmationNumber, receipt) => {
            setIsUpdatingTransfer(false);
            if (receipt.status) {
              console.log("receipt", receipt);
              setIsApprove(true);
            } else {
            }
          })
          .on("error", (error) => {
            setErrorMSG("Failed");
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.error("error", error);
          });
      } catch (err) {
        console.error("ERROR: ".err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const transferHandler = async () => {
    if (transferAmount !== "" && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(EthSwapAgentABIData, ETHswap);
        contract.methods
          .swapETH2BSC(
            BinanceTokenAddress,
            web3.utils.toWei(transferAmount.toString())
          )
          .send({ from: account, gas: 3000000, value: TokenValue })
          .then(async function (receipt) {
            if (receipt.status) {
              setOpen(true);
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                "fillETH2BSCSwapFun"
              );
              setIsApprove(false);
              setOpen(false);
              setTransferAmount("");
              getBalanceFun(account);
              setIsUpdatingTransfer(false);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .catch((err) => {
            setErrorMSG("Failed");
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.log("ERROR", err);
          });
      } catch (err) {
        console.log("err", err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const approveToken = async () => {
    if (transferAmount !== "" && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));

        const contract = new web3.eth.Contract(
          IBEP20ABIData,
          BinanceTokenAddress
        );
        const transactionParameters = {
          to: BinanceTokenAddress,
          from: account,
          data: contract.methods
            .approve(ETHswap, web3.utils.toWei(transferAmount.toString()))
            .encodeABI(),
        };
        web3.eth
          .sendTransaction(transactionParameters)
          .on("transactionHash", (hash) => {})
          .once("confirmation", (confirmationNumber, receipt) => {
            if (receipt.status) {
              setIsUpdatingTransfer(false);
              setIsApprove(true);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .on("error", (error) => {
            setErrorMSG("Failed");
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.error("error", error);
          });
      } catch (err) {
        console.error("ERROR: ".err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const fillSwapFun = async (hash, userAddress, tokenAmount, endPoint) => {
    try {
      const res = await axios.post(
        `https://backend.gainpool.io/api/v1/block/${endPoint}`,
        {
          hash: hash,
          userAddress: userAddress,
          tokenAmount: tokenAmount,
        }
      );
      if (res.data.responseCode) {
        return true;
      }
    } catch (err) {
      console.log("ERROR", err);
      return false;
    }
  };

  useEffect(() => {
    setTokenBalanceBinance();
    setTokenBalanceEthereum(0);
    setTotalSupplyEthereum(0);
    setTotlaSupplyBinance(0);
    if (account) {
      getBalanceFun(account);
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBalanceFun = async (account) => {
    setTokenBalanceBinance(await getBinanceBalance(account));
    setTokenBalanceEthereum(await getEthereumBalance(account));
    setTotalSupplyEthereum(
      await getTotalSupply(IBEP20ABIData, ETHTokenAddress, account, ethNode)
    );
    setTotlaSupplyBinance(
      await getTotalSupply(
        BEP20IMPLABIData,
        BinanceTokenAddress,
        account,
        binanceNode
      )
    );
  };

  return (
    <>
      <Container maxWidth="md">
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="info">
            Your tokens will be bridged to{" "}
            {currentChain === BinanceChainId ? "ETH" : "BSC"} shortly
          </Alert>
        </Snackbar>

        <Snackbar
          open={misMatchArraw || isError}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error">
            {isError ? errorMSG : "Switch Network"}
          </Alert>
        </Snackbar>

        <Box my={4}>
          <Box p={1} justifyItems="center" alignItems="center">
            <Box
              style={{
                border: "2px solid #27B5E7",
                backgroundColor: "#27B5E7",
                color: "white",
                borderRadius: 90,
                width: "60%",
                marginLeft: "20%",
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 10,
              }}
            >
              <Typography variant="h6" style={{ textAlign: "center" }}>
                {currentChain === EthereumChainId
                  ? "ETH to BSC  Bridge"
                  : "BSC to ETH Bridge"}
              </Typography>
            </Box>
          </Box>
          <Grid container className={classes.mainContainer}>
            <Grid item xs={6} className={classes.containerLeft}>
              <Box p={4}>
                <Typography variant="caption" className={classes.caption}>
                  Binance Smart Chain
                </Typography>
                <br />
                <Typography variant="h5" className={classes.blueText}>
                  GAIN BEP20
                </Typography>
                <Typography variant="h5">
                  {tokenBalanceBinance
                    ? tokenBalanceBinance.binanceBalance
                    : "0"}{" "}
                </Typography>
                <Button
                  className={classes.blueText}
                  size="small"
                  onClick={() => setBinance(true)}
                  disabled={tokenBalanceBinance ? false : true}
                >
                  Show more
                </Button>
                {currentChain === EthereumChainId ? (
                  <ArrowBackIcon
                    className={classes.arrowIcon}
                    onClick={() => toogleArrawHandler()}
                  />
                ) : (
                  <ArrowForwardIcon
                    className={classes.arrowIcon}
                    onClick={() => toogleArrawHandler()}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={6} className={classes.containerRight}>
              <Box pt={4} pl={7}>
                <Typography variant="caption" className={classes.caption}>
                  Ethereum Mainnet
                </Typography>
                <br />
                <Typography variant="h5" className={classes.blueText}>
                  GAIN ERC20
                </Typography>
                <Typography variant="h5">
                  {tokenBalanceEthereum ? tokenBalanceEthereum : "0"}{" "}
                </Typography>
                <Button
                  className={classes.blueText}
                  size="small"
                  onClick={() => setEthereum(true)}
                  disabled={tokenBalanceEthereum ? false : true}
                >
                  Show more
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={openEthereum}
          onClose={() => setEthereum(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
          PaperProps={{ style: { borderRadius: 23 } }}
        >
          <Box display="flex" p={2} pl={2} alignItems="center">
            <img src={EthLogo} alt="eth mannet" width="43px" />
            <Typography variant="h6">Ethereum Mainnet</Typography>
          </Box>

          <DialogContent className={classes.dialogueContent}>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Bridge Address</Typography>
              <Box display="flex">
                <Typography variant="body1">{sortAddress(BSCswap)}</Typography>
                <Link
                  href={`https://bscscan.com/address/${BSCswap}`}
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#3220ac"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    width="20px"
                    className={`${classes.links} jss226`}
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </Link>
              </Box>
            </Box>

            <Divider light={true} />
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Token Address</Typography>

              <Box display="flex">
                <Typography variant="body1">
                  {sortAddress(ETHTokenAddress)}
                </Typography>
                <Link
                  href={`https://etherscan.io/address/${ETHTokenAddress}`}
                  className={classes.links}
                  target="_blank"
                >
                  <svg
                    className={`${classes.links} jss226`}
                    // class="jss226"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#3220ac"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    width="20px"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </Link>
              </Box>
            </Box>
            <Divider light={true} />

            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Total Token Supply</Typography>
              <Typography variant="body1" style={{ paddingRight: "32px" }}>
                {totalSupplyEthereum}
              </Typography>
            </Box>
            <Divider light={true} />

            {/* <Divider light={true} /> */}

            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Your GAIN Balance</Typography>
              <Box display="flex">
                <Typography variant="body1" style={{ paddingRight: "32px" }}>
                  {tokenBalanceEthereum}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openBinance}
          onClose={() => setBinance(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
          className={classes.curvedDialogue}
          PaperProps={{ style: { borderRadius: 23 } }}
        >
          <Box display="flex" p={3} pl={2} alignItems="center">
            <img
              src={BinanceLogo}
              alt="eth mannet"
              width="43px"
              style={{ marginRight: "12px" }}
            />
            <Typography variant="h6">Binance Smart Chain</Typography>
          </Box>

          <DialogContent className={classes.dialogueContent}>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Bridge Address</Typography>
              <Box display="flex">
                <Typography variant="body1">{sortAddress(ETHswap)}</Typography>
                <Link
                  href={`https://bscscan.com/address/${ETHswap}`}
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#3220ac"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    width="20px"
                    className={`${classes.links} jss226`}
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </Link>
              </Box>
            </Box>

            <Divider light={true} />
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Token Address</Typography>

              <Box display="flex">
                <Typography variant="body1">
                  {sortAddress(BinanceTokenAddress)}
                </Typography>
                <Link
                  href={`https://bscscan.com/address/${BinanceTokenAddress}`}
                  className={classes.links}
                  target="_blank"
                >
                  <svg
                    className={`${classes.links} jss226`}
                    // class="jss226"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#3220ac"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    width="20px"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </Link>
              </Box>
            </Box>
            <Divider light={true} />

            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Total Token Supply</Typography>
              <Typography variant="body1" style={{ paddingRight: "32px" }}>
                {totlaSupplyBinance}
              </Typography>
            </Box>
            <Divider light={true} />

            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Total GAIN Locked</Typography>
              <Box display="flex">
                <Typography variant="body1" style={{ paddingRight: "32px" }}>
                  {tokenBalanceBinance && tokenBalanceBinance.lockedBalance}
                </Typography>
              </Box>
            </Box>

            <Divider light={true} />

            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.itemBox}
            >
              <Typography variant="h6">Your GAIN Balance</Typography>
              <Box display="flex">
                <Typography variant="body1" style={{ paddingRight: "32px" }}>
                  {tokenBalanceBinance && tokenBalanceBinance.binanceBalance}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>

      <Container maxWidth="md">
        <Grid
          container
          className={classes.mainContainer}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item style={{ paddingRight: 34 }}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h5" className={classes.caption}>
                  Transfer Amount
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  style={{ color: "#015284" }}
                  disabled={tokenBalanceBinance ? false : true}
                  onClick={() =>
                    currentChain === BinanceChainId
                      ? setTransferAmount(tokenBalanceBinance.binanceBalance)
                      : setTransferAmount(tokenBalanceEthereum)
                  }
                >
                  Max
                </Button>
              </Grid>
            </Grid>
            <Grid item mt={2}>
              <TextField
                placeholder="Enter amount to be transferred"
                inputProps={{ style: { fontSize: 14, minWidth: "260px" } }}
                fullWidth={true}
                className="transfer-input"
                value={transferAmount}
                onChange={(e) => setTransferAmount(e.target.value)}
              />
            </Grid>
          </Grid>
          {/* <Grid item> */}
          <Box height="100%" display="flex" alignItems="center" mt={5}>
            <Button
              variant="contained"
              className={classes.transferButton}
              // endIcon={<ArrowForwardIcon />}
              disableRipple
              disabled={
                isUpdatingTransfer ||
                isApprove ||
                misMatchArraw ||
                account === null
              }
              onClick={
                currentChain === BinanceChainId ? approveToken : approveTokenBNB
              }
            >
              Approve
              {isUpdatingTransfer && !isApprove && <ButtonCircularProgress />}
            </Button>
            <Button
              variant="contained"
              className={classes.transferButton}
              endIcon={<ArrowForwardIcon />}
              disableRipple
              disabled={isUpdatingTransfer || !isApprove || misMatchArraw}
              onClick={
                currentChain === BinanceChainId
                  ? transferHandler
                  : transferHandlerBNB
              }
            >
              Transfer
              {isUpdatingTransfer && isApprove && <ButtonCircularProgress />}
            </Button>
          </Box>
          {/* </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default Bridge;
