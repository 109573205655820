import { ethers } from 'ethers';
import Web3 from 'web3';
import {
  ETHTokenAddress,
  IBEP20ABIData,
  BinanceTokenAddress,
  ETHswap,
  binanceNode,
  ethNode,
} from '../Utils/index';

export function checkMetaMask() {
  let isMetaMask = false;
  if (window.ethereum) {
    isMetaMask = true;
  }
  return isMetaMask;
}

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export async function getProvider() {
  const provider = await new ethers.providers.Web3Provider(window.ethereum);
  return provider;
}

export async function getBinanceBalance(account) {
  const testnode = binanceNode;

  const web3 = new Web3(new Web3.providers.HttpProvider(testnode));

  const contract = new web3.eth.Contract(IBEP20ABIData, BinanceTokenAddress);

  const tx = await contract.methods.balanceOf(account).call();

  const tx1 = await contract.methods.balanceOf(ETHswap).call();
  let obj = {
    binanceBalance: web3.utils.fromWei(tx),
    lockedBalance: web3.utils.fromWei(tx1),
  };
  return obj;
}

export async function getEthereumBalance(account) {
  const testnode = ethNode;
  const web3 = new Web3(new Web3.providers.HttpProvider(testnode));

  const contract = new web3.eth.Contract(IBEP20ABIData, ETHTokenAddress);

  const tx = await contract.methods.balanceOf(account).call();

  return web3.utils.fromWei(tx);
}

export const getTotalSupply = async (abi, address, account, testnodeUrl) => {
  let totolSupply = 0;
  try {
    const testnode = testnodeUrl;
    const web3 = new Web3(new Web3.providers.HttpProvider(testnode));

    const contract = new web3.eth.Contract(abi, address);
    await contract.methods
      .totalSupply()
      .call({ from: account, gas: 3000000 })
      .then(async function (receipt) {
        totolSupply = await web3.utils.fromWei(receipt);
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  } catch (err) {
    console.log('err', err);
  }
  return totolSupply;
};

export async function getSignerData() {
  const provider = await new ethers.providers.Web3Provider(window.ethereum);
  const signer = await provider.getSigner();
  return signer;
}

export function getMyAccount() {
  const myAdd = window.sessionStorage.getItem('account');
  return myAdd;
}

export async function getMyBalance() {
  const myAdd = getMyAccount();
  const provider = await getProvider();
  const providerBalance = await provider.getBalance(myAdd);
  return providerBalance;
}

export async function connectToWallet() {
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  const provider = await getProvider();
  const signer = await provider.getSigner();
  let myAddress = await signer.getAddress();
  window.sessionStorage.setItem('account', myAddress);
  return myAddress;
}
