import BSCSwapAgentABI from "./BSCSwapAgent_ABI.json";
import EthSwapAgentABI from "./EthSwapAgent_ABI.json";

import IBEP20ABI from "./IBEP20_ABI.json";
import BEP20IMPLABI from "./BEP20IMPLABI.json";

import ERC20Abi from "./ERC20Abi.json";

export const TokenValue = "5000000000000000";

export const BinanceTokenAddress = "0xAAc073847e989C6D9d2f115A69E57a024415c684";
export const ETHswap = "0x5D4b65119500ef95BCC84Ab7373f4f2a3ED3C707";

export const ETHTokenAddress = "0x7ecaee0d064bf52f058d036991371c2dac565fb4";
export const BSCswap = "0x45De01211c412BDDB9952831890Bd9b68DA6B136";

export const AdminAddress = "0";
export const AdminPriKey = "0";

export const EthereumChainId = 1;
export const BinanceChainId = 56;

export const BSCSwapAgentABIData = BSCSwapAgentABI;
export const EthSwapAgentABIData = EthSwapAgentABI;
export const IBEP20ABIData = IBEP20ABI;
export const ERC20AbiData = ERC20Abi;
export const BEP20IMPLABIData = BEP20IMPLABI;

export const binanceNode = "https://bsc-dataseed.binance.org/";

export const ethNode =
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
