import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import { sortAddress } from "../hooks/operations";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles((theme) => ({
  connect: {
    backgroundColor: "#27B5E7",
    borderColor: "#27B5E7",
    borderRadius: 90,
    color: "white",
    "&:hover": {
      background: "#27B5E7",
      color: "white",
    },
  },
  dialog: {
    minWidth: 150,
    position: "fixed",
    top: 20,
    right: "25%",
  },
}));

const Header = ({ account, connect }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const logoutHandler = () => {
    window.sessionStorage.removeItem("account");
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <Toolbar>
      <Container
        maxWidth="md"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Logo />
        <Box display="flex" alignItems="center">
          {/* <Button
            variant="text"
            style={{ color: "#015284" }}
            target="#"
            href="https://medium.com/zeroswaplabs/step-by-step-guide-to-mint-zee-bep20-with-the-binance-smart-chain-bridge-a74e476a67ad"
          >
            Guide
          </Button> */}
          {account === null ? (
            <Button
              variant="outlined"
              className={classes.connect}
              onClick={connect}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              style={{ borderRadius: 90, paddingLeft: 20, paddingRight: 20 }}
              onClick={handleClickOpen}
            >
              <Typography variant="body1">
                {sortAddress(account && account)}
              </Typography>
            </Button>
          )}
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: classes.dialog,
          }}
          // style={{ minWidth: 150, position: 'absolute', top: 20, right: '25%' }}
        >
          <DialogContent style={{ padding: 5 }}>
            <Button fullWidth onClick={logoutHandler}>
              Logout
            </Button>
          </DialogContent>
        </Dialog>
      </Container>
    </Toolbar>
  );
};

export default Header;
